*:focus-visible {
  outline: 4px solid #93b3f2 !important;
}

/* hide scrollbars under 1280px */
@media screen and (max-width: 1279px) {
  *::-webkit-scrollbar {
    display: none;
  }

  * {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

* {
  font-family: "Cooper Hewitt" !important;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html {
  font-size: 1rem;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p,
h1,
h2,
h3,
h4 {
  margin: 0;
}

.html ins {
  text-decoration: none !important;
}

.white-ripple .MuiTouchRipple-child {
  background-color: white !important;
}

.border-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bold-500 {
  font-weight: 500;
}

/* CSS Animations Classes */
.fade-enter {
  opacity: 0.5;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.5;
  transition: opacity 250ms ease-out;
}

.fade-stories-enter {
  opacity: 0.5;
  z-index: 1;
}

.fade-stories-enter.fade-stories-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-out;
}

.fade-stories-exit {
  opacity: 1;
}

.fade-stories-exit.fade-stories-exit-active {
  opacity: 0.5;
  transition: opacity 250ms ease-out;
}

.hide-scrollbars::-webkit-scrollbar {
  display: none;
}

.hide-scrollbars {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
