@font-face {
  font-family: "Cooper Hewitt";
  font-style: normal;
  font-weight: 800;
  src: local("Cooper Hewitt"),
    url(../assets/fonts/CooperHewitt-Heavy.otf) format("opentype");
}

@font-face {
  font-family: "Cooper Hewitt";
  font-style: italic;
  font-weight: 800;
  src: local("Cooper Hewitt"),
    url(../assets/fonts/CooperHewitt-HeavyItalic.otf) format("opentype");
}

@font-face {
  font-family: "Cooper Hewitt";
  font-style: normal;
  font-weight: 700;
  src: local("Cooper Hewitt"),
    url(../assets/fonts/CooperHewitt-Bold.otf) format("opentype");
}

@font-face {
  font-family: "Cooper Hewitt";
  font-style: italic;
  font-weight: 700;
  src: local("Cooper Hewitt"),
    url(../assets/fonts/CooperHewitt-BoldItalic.otf) format("opentype");
}

@font-face {
  font-family: "Cooper Hewitt";
  font-style: normal;
  font-weight: 600;
  src: local("Cooper Hewitt"),
    url(../assets/fonts/CooperHewitt-Semibold.otf) format("opentype");
}

@font-face {
  font-family: "Cooper Hewitt";
  font-style: italic;
  font-weight: 600;
  src: local("Cooper Hewitt"),
    url(../assets/fonts/CooperHewitt-SemiboldItalic.otf) format("opentype");
}

@font-face {
  font-family: "Cooper Hewitt";
  font-style: normal;
  font-weight: 500;
  src: local("Cooper Hewitt"),
    url(../assets/fonts/CooperHewitt-Medium.otf) format("opentype");
}

@font-face {
  font-family: "Cooper Hewitt";
  font-style: italic;
  font-weight: 500;
  src: local("Cooper Hewitt"),
    url(../assets/fonts/CooperHewitt-MediumItalic.otf) format("opentype");
}

@font-face {
  font-family: "Cooper Hewitt";
  font-style: normal;
  font-weight: 400;
  src: local("Cooper Hewitt"),
    url(../assets/fonts/CooperHewitt-Book.otf) format("opentype");
}

@font-face {
  font-family: "Cooper Hewitt";
  font-style: italic;
  font-weight: 400;
  src: local("Cooper Hewitt"),
    url(../assets/fonts/CooperHewitt-BookItalic.otf) format("opentype");
}

@font-face {
  font-family: "Cooper Hewitt";
  font-style: normal;
  font-weight: 300;
  src: local("Cooper Hewitt"),
    url(../assets/fonts/CooperHewitt-Light.otf) format("opentype");
}

@font-face {
  font-family: "Cooper Hewitt";
  font-style: italic;
  font-weight: 300;
  src: local("Cooper Hewitt"),
    url(../assets/fonts/CooperHewitt-LightItalic.otf) format("opentype");
}

@font-face {
  font-family: "Cooper Hewitt";
  font-style: normal;
  font-weight: 200;
  src: local("Cooper Hewitt"),
    url(../assets/fonts/CooperHewitt-Thin.otf) format("opentype");
}

@font-face {
  font-family: "Cooper Hewitt";
  font-style: italic;
  font-weight: 200;
  src: local("Cooper Hewitt"),
    url(../assets/fonts/CooperHewitt-ThinItalic.otf) format("opentype");
}
